<template>
  <div class="dialog-container">
    <transition>
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateDetailScreen'" key="detail-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="details-loading">
        <div class="dialog-title" v-if="detailsObj">
          <div class="head-title">Details {{ detailsObj.Title }}<span class="yellow-dot">.</span></div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form" v-if="detailsObj">
          <div>
            <el-col :sm="24" class="" style="display: flex">
              <div class="title">SKU: {{ detailsObj.SKU }} - Customer: {{ detailsObj.Customer.CompanyName }} - Product Name: {{ detailsObj.Title }}</div>
            </el-col>
          </div>
          <el-table id="product-details" v-if="detailsObj.Details[0]" :data="detailsObj.Details" style="width: 100%">
            <el-table-column prop="Box.BoxDimensions.X" label="X (CM)" align="center" min-width="60"> </el-table-column>
            <el-table-column prop="Box.BoxDimensions.Y" label="Y (CM)" align="center" min-width="60"> </el-table-column>
            <el-table-column prop="Box.BoxDimensions.Z" label="Z (CM)" align="center" min-width="60"> </el-table-column>
            <el-table-column prop="Box.Weight" label="Weight (KG)" align="center" min-width="100"> </el-table-column>
            <el-table-column prop="Box.PiecesInBox" label="Pieces in Box" align="center" min-width="100"> </el-table-column>
            <el-table-column prop="TotalAvailable" label="Total Available" align="center" min-width="100"> </el-table-column>
            <el-table-column prop="Title" label="Warehouses" align="center" min-width="150">
              <el-table-column :label="item.WarehouseTitle" align="center" min-width="150" v-for="(item, index) in detailsObj.Details[0].WarehouseBoxList" :key="index + 'Warehouse'">
                <template slot-scope="scope">
                  {{ scope.row.WarehouseBoxList.find((x) => x.WarehouseId == item.WarehouseId).BoxQuantity }}
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="InProduction" label="Production" align="center" min-width="100"></el-table-column>
            <el-table-column prop="InSupplierDepot" label="Supplier Depot" align="center" min-width="100"></el-table-column>
            <el-table-column prop="InTransit" label="Transit" align="center" min-width="100"></el-table-column>
            <el-table-column prop="Total" label="Total" align="center" min-width="100"> </el-table-column>

            <el-table-column prop="Consumption3Months" label="3 Months Consumption" align="center" min-width="150"></el-table-column>
            <el-table-column prop="Consumption12Months" label="12 Months Consumption" align="center" min-width="150"></el-table-column>
          </el-table>
          <div class="line"></div>
          <Bar :chart-data="detailsObj.Statistics.ChartData" :width="800" :height="200" />
          <el-tabs type="border-card" :stretch="true">
            <el-tab-pane>
              <span slot="label"
                ><i class="icon-clock-rotate"></i> Updates <span class="notifications">{{ detailsObj.StockHistories.length > 9 ? "9+" : detailsObj.StockHistories.length }}</span>
              </span>
              <div class="updates-timeline form">
                <el-timeline>
                  <el-timeline-item :timestamp="$moment(item.Date).format('MMMM Do YYYY, h:mm:ss A')" placement="top" v-for="(item, index) in detailsObj.StockHistories" :key="index">
                    <el-card>
                      <div class="content">{{ item.Note }}</div>
                      <div class="updates-bottom">
                        <div class="bottom">
                          <div class="circle">{{ item.Employee.FirstName[0] + "" + item.Employee.LastName[0] }}</div>
                          <div class="user">
                            <div class="user-info">{{ item.Employee.FullName }}</div>
                            <div class="status">
                              <span>{{ getEnumsDisplay("TypeOfAuthority", item.Employee.Role) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-table :data="detailsObj.StockHistories" style="width: 100%">
            <el-table-column prop="Warehouse" label="Warehouse" align="center" min-width="50"> </el-table-column>
            <el-table-column prop="Employee.FullName" label="User" align="center"> </el-table-column>
            <el-table-column prop="BoxQuantity" label="Box Quantity" align="center" min-width="50"> </el-table-column>
            <el-table-column prop="PalletQuantity" label="Pallet Quantity" align="center" min-width="70"> </el-table-column>
            <el-table-column prop="Date" label="Date" align="center" min-width="50">
              <template slot-scope="scope">
                {{ $moment(scope.row.Date).format("DD-MM-YYYY") }}
              </template>
             </el-table-column>
            <el-table-column prop="Note" label="Note" align="center" min-width="120"> </el-table-column>
            <el-table-column prop="HistoryStatus" label="Status" min-width="50">
               <template slot-scope="scope">
                <div class="status" :class="{ success: scope.row.HistoryStatus == 0, danger: scope.row.HistoryStatus == 1 }">
                  <span v-if="getEnums">{{ getEnumsDisplay("StockStatus", scope.row.HistoryStatus) }}</span>
                </div>
              </template> 
            </el-table-column>
          </el-table>
          <div class="dialog-footer footer-button">
            <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          </div>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  props: ["id"],

  name: "Products-Details",

  components: {
    Bar,
  },

  data() {
    return {
      detailsObj: null,
      seeDetails: false,
      loading: false,
      timeout: null,
      myChart: null,
    };
  },
  async created() {
    await this.$store.dispatch("getProductsList");

    await this.setId();
  },

  methods: {
    close(id) {
      this.$emit("close", id);
    },
    async setId() {
      this.$nextTick(() => {
        this.detailsObj = this.productsList.find((x) => x.ID == this.id);
        this.detailsObj.Statistics.ChartData.datasets[0].backgroundColor = "#39b6ad";
        this.detailsObj.Statistics.ChartData.datasets[0].label = "Consumption";
      });
    },
  },
  computed: {
    productsList() {
      return this.$store.getters.getProductsList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
  },
};
</script>

<style></style>
