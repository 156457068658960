<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen'" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading"></el-row>
    </transition>
    <div class="dialog-title">
      <div class="head-title">Add New Product<span class="yellow-dot">.</span></div>
      <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
    </div>
    <div class="form" v-if="productForm">
      <el-row :gutter="24">
        <el-col :sm="12">
          <div class="title">Customer<span class="yellow-dot">.</span></div>
          <div class="left">
            <el-select style="width: 100%; margin-top: 15px" filterable placeholder="Select" v-model="productForm.Customer.ID" @change="setCustomer">
              <el-option v-for="(item, index) in customerList" :key="item.ID + index" :label="item.CompanyName" :value="item.ID"> </el-option>
            </el-select>
            <span class="error-validation">{{ $errorMessage("Customer", validation) }}</span>
          </div>
        </el-col>

        <el-col :sm="12" class="right-card" v-if="customer">
          <div class="inline-form">
            <div class="input-group">
              <div class="left">Date:</div>
              <div class="right" v-if="customer">{{ $moment(customer.CreatedDateTime).format("DD-MM-YYYY") }}</div>
            </div>
            <div class="input-group">
              <div class="left">Customer Name:</div>
              <div class="right" v-if="customer && customer.FirstName && customer.LastName">{{ customer.FirstName + " " + customer.LastName }}</div>
            </div>
            <div class="input-group">
              <div class="left">Company Name:</div>
              <div class="right" v-if="customer && customer.CompanyName">{{ customer.CompanyName }}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="title">Product Informations<span class="yellow-dot">.</span></div>
      <div class="bg">
        <div class="mini-form">
          <el-row>
            <div class="input-container-row">
              <el-switch v-model="selectType" active-text="Product Name" inactive-text="Select Products" @change="resetForm"> </el-switch>
            </div>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8" class="input-container" v-if="!selectType">
              <div class="text">Select Product</div>
              <el-select placeholder="Select Product" clearable filterable v-model="productForm.Product" value-key="ID" @change="createDescription">
                <el-option v-for="product in sampleProductList" :key="product.ID" :value="product" :label="product.Description"></el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("Product.Title", validation) }}</span>
            </el-col>
            <el-col :sm="8" class="input-container" v-if="selectType">
              <div class="text">Products Name</div>
              <el-input v-model="productForm.Product.Title" placeholder="..." @change="createDescription"></el-input>
              <span class="error-validation">{{ $errorMessage("Product.Title", validation) }}</span>
            </el-col>

            <el-col :sm="16" class="input-container">
              <div class="text">Description</div>
              <el-input v-model="productForm.Product.Description" placeholder="..."></el-input>
            </el-col>
          </el-row>
          <el-switch @change="resetDiameter" v-model="productForm.Product.Diameter" inactive-text="W-L-H" active-text="Other" ></el-switch>
          <el-row :gutter="24" v-if="!productForm.Product.Diameter">
            <el-col class="input-container" :sm="8">
              <div class="text">Width</div>
              <el-input @change="createDescription" v-model="productForm.Product.Width" placeholder="..." >
                <div slot="append">CM</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Width", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Length</div>
              <el-input @change="createDescription" v-model="productForm.Product.Length" placeholder="..." >
                <div slot="append">CM</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Length", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Height</div>
              <el-input @change="createDescription" v-model="productForm.Product.Height" placeholder="..." >
                <div slot="append">CM</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Height", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-if="productForm.Product.Diameter">
            <el-col class="input-container" :sm="8">
              <div class="text">Other</div>
              <el-input @change="createDescription" v-model="productForm.Product.DiameterValue" placeholder="..." >
                <el-select @change="createDescription" v-model="productForm.Product.Unit" slot="append" class="append-diameter prod-append" placeholder="..." clearable @clear="item.Unit == null">
                  <el-option v-for="(item, index) in getEnums.find((x) => x.Title == 'Unit').Enums" :key="item.Key + index" :label="item.DisplayName" :value="item.Key"></el-option>
                </el-select>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Product.DiameterValue", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Print</div>
              <el-input v-model="productForm.Product.Print" placeholder="..." class="select"> </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Print", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Alternative Print Options</div>
              <el-select v-model="selectedPrint" @change="updateAlternativePrint" placeholder="Select" class="select">
                <el-option v-for="(alternativePrints, alternativePrintIndex) in PrintAlternatives" :key="alternativePrintIndex + '-print'" :label="alternativePrints.AlternativePrint" :value="alternativePrints.AlternativePrint"> </el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Quality</div>
              <el-input v-model="productForm.Product.Quality" placeholder="..." class="select"> </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Quality", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Type</div>
              <el-input v-model="productForm.Product.Type" placeholder="..." class="select"> </el-input>
              <span class="error-validation">{{ $errorMessage("Product.Type", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>

          <el-row :gutter="24">
            <el-col :span="8">
              <div class="text">Quantity</div>
            </el-col>
            <el-col :span="16">
              <el-input v-model="productForm.Product.Quantity" placeholder="..."> </el-input>
              <span class="error-validation">{{ $errorMessage("Box.Quantity", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="text">Unit Price</div>
            </el-col>
            <el-col :span="16">
              <el-input v-model="productForm.Product.UnitPrice" placeholder="..."> </el-input>
              <span class="error-validation">{{ $errorMessage("Box.UnitPrice", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="text">Pieces in Box</div>
            </el-col>
            <el-col :span="16">
              <el-input v-model="productForm.Box.PiecesInBox" placeholder="..." @change="calculateTotal"> </el-input>
              <span class="error-validation">{{ $errorMessage("Box.PiecesInBox", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="text">Box Dimensions</div>
            </el-col>
            <el-col :span="16" class="dimensions-active mini-form">
              <el-input v-model="productForm.Box.BoxDimensions.X" placeholder="...">
                <div slot="append">W</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.X", validation) }}</span>

              <el-input v-model="productForm.Box.BoxDimensions.Y" placeholder="...">
                <div slot="append">L</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Y", validation) }}</span>

              <el-input v-model="productForm.Box.BoxDimensions.Z" placeholder="...">
                <div slot="append">H</div>
              </el-input>
              <span class="error-validation">{{ $errorMessage("Box.BoxDimensions.Z", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="text">Weight</div>
            </el-col>
            <el-col :span="16">
              <el-input v-model="productForm.Box.Weight" placeholder="..."> </el-input>
              <span class="error-validation">{{ $errorMessage("Box.Weight", validation) }}</span>
            </el-col>
          </el-row>
          <div class="mini-form" v-for="(warehouse, index) in productForm.Warehouses" :key="warehouse.ID + index">
            <div class="line"></div>
            <el-row :gutter="24">
              <el-col :span="8">
                <div class="text product-inf-button-groups">Warehouse Name</div>
              </el-col>
              <el-col :span="16">
                <el-select v-model="productForm.Warehouses[index].ID" placeholder="Select Warehouse" class="select" style="width: 100%" disabled>
                  <el-option v-for="item in getWarehouseList" :key="item.Key" :label="item.Title" :value="item.ID"> </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <div class="text">Pallet Quantity</div>
              </el-col>
              <el-col :span="16">
                <el-input v-model.number="productForm.Warehouses[index].PalletQuantity" placeholder="..."> </el-input>
                <span class="error-validation">{{ $errorMessage("Warehouses[" + index + "].PalletQuantity", validation) }}</span>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="8">
                <div class="text">Box Quantity</div>
              </el-col>
              <el-col :span="16">
                <el-input v-model.number="productForm.Warehouses[index].BoxQuantity" placeholder="..." @change="calculateTotal"> </el-input>
                <span class="error-validation">{{ $errorMessage("Warehouses[" + index + "].BoxQuantity", validation) }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div class="dialog-footer footerbutton">
        <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
        <el-button class="btn" type="primary" @click="saveAddProductions" :loading="loading == 'saveLoading'"> <i class="icon-floppy-disk-solid" v-if="loading == false"></i> Save</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "add-products",
  components: {},
  data() {
    return {
      PrintAlternatives: [
        {
          AlternativePrint: " Customer Design ",
        },
        {
          AlternativePrint: " Color 1 ",
        },
        {
          AlternativePrint: " Color 2 ",
        },
      ],
      selectedPrint: null,
      selectType: false,
      productForm: null,
      customer: null,
      loading: false,
      timeout: null,
    };
  },
  async created() {
    this.productForm = await this.getItemForm;

    await Promise.all([this.$store.dispatch("getProductSamplesList", { PageSize: 9999999, Page: 1, SearchTerm: null }), this.$store.dispatch("getProductsList", this.filter), this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null }), this.$store.dispatch("getWarehouseList"), this.$store.commit("setWarehouseValidation", [])]);
    this.$nextTick(() => {
      this.productForm = this.getItemForm;
    });
    this.$nextTick(() => {
      console.log(this.getWarehouseList, "asd");

      this.productForm.Warehouses = this.getWarehouseList.map((x) => ({ ID: x.ID, BoxQuantity: 0, PalletQuantity: 0 }));
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },
    createDescription() {
      var x;

      if (this.productForm.Product.Unit == 0) {
        x = '"';
      } else if (this.productForm.Product.Unit == 1) {
        x = "oz";
      } else if (this.productForm.Product.Unit == 2) {
        x = "Ø";
      } else if (this.productForm.Product.Unit == 3) {
        x = "cc";
      } else {
        x = "";
      }
      if (this.productForm.Product.Diameter) {
        this.productForm.Product.Description = "Bespoke " + this.productForm.Product.DiameterValue + " " + x + " " + this.productForm.Product.Title;
      } else {
        this.productForm.Product.Description = "Bespoke " + this.productForm.Product.Width + "x" + this.productForm.Product.Length + "x" + this.productForm.Product.Height + "cm " + this.productForm.Product.Title;
      }
    },

    resetForm() {
      this.productForm.Product = {
        Description: null,
        ProductNo: null,
        Diameter: false,
        Unit: null,
        DiameterValue: 0,
        Width: 0,
        Length: 0,
        Height: 0,
        Print: null,
        Quality: null,
        Type: null,
        UnitPrice: 0,
        Quantity: 0,
        IsProduced: true,
        Title: null,
        SupplierUnitPrice: 0,
        ID: null,
      };
    },
    resetDiameter() {
      this.productForm.Product.Unit = null;
      this.productForm.Product.DiameterValue = 0;
      this.productForm.Product.Width = 0;
      this.productForm.Product.Height = 0;
      this.productForm.Product.Length = 0;
    },
    updateAlternativePrint() {
      this.productForm.Product.Print = this.selectedPrint;
      this.selectedPrint = null;
    },

    async saveAddProductions() {
      this.$store.commit("setWarehouseValidation", []);
      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.productForm.Product.Width == "") {
          this.productForm.Product.Width = null;
        }
        var res = await this.$store.dispatch("AddUpdateWarehouseItem", this.productForm);
        if (res.HasError) {
          console.log("asdd");
        } else {
          await this.$store.dispatch("getProductsList", { isDesignConfirmed: true });
          this.productForm = this.getItemForm;

          this.close();
        }
        this.loading = false;
      }, 500);
    },
    setCustomer(id) {
      this.productForm.Customer.ID = id;
      this.customer = this.customerList.find((x) => x.ID == id);
    },

    calculateTotal() {
      this.productForm.Product.Quantity = this.productForm.Box.PiecesInBox * this.productForm.Warehouses.map((x) => x.BoxQuantity).reduce((a, b) => a + b, 0);
    },
  },
  computed: {
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    getItemForm() {
      return this.$store.getters.getWarehouseItemForm;
    },
    getWarehouseList() {
      return this.$store.getters.getWarehouseList;
    },
    getEnums() {
      return this.$store.getters.getEnums;
    },
    validation() {
      return this.$store.getters.getWarehousesValidation;
    },
    sampleProductList() {
      return this.$store.getters.getProductSamplesList;
    },
  },
  beforeDestroy() {
    this.resetForm();
  },
};
</script>

<style></style>
