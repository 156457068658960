<template>
  <div id="products">
    <portal to="filter">
      <el-header class="filter-header">
        <div class="title">Products List</div>
        <div class="actions-buttons">
          <el-button type="secondary" class="btn" :class="{ 'active-filter': filterActive ? true : false }" @click="toggle"><i class="icon-filter-filter"></i> Filters</el-button>
          <el-button type="info" class="btn" @click="exportFile()"><i class="icon-filter-export"></i>Export</el-button>

          <el-button type="primary" class="add-btn" @click="openAddDialog(null)">
            <i class="el-icon-loading" style="font-size: 18px; color: white; background-color: transparent" v-if="loading == 'openAddDialogLoading'"></i>
            <i class="icon-plus-solid" v-else></i>Add New Product
          </el-button>
        </div>
      </el-header>
    </portal>
    <div class="filter-container">
      <div class="header">
        <div class="title">Filters</div>
        <el-button type="danger" class="btn" @click="clearFilter">
          <i class="icon-filter-close"></i>
          Clear Filter
        </el-button>
      </div>
      <div class="all-inputs">
        <el-row :gutter="36">
          <el-col :sm="8">
            <el-input placeholder="Search Product Name" prefix-icon="el-icon-search" v-model="filter.SearchTerm" clearable></el-input>
          </el-col>
          <el-col class="input-container" :sm="8">
            <el-input placeholder="SKU" prefix-icon="el-icon-search" v-model="filter.SKU" clearable></el-input>
          </el-col>
        </el-row>
        <el-row :gutter="36">
          <el-col class="input-container" :sm="8">
            <div class="text">Customer Name</div>
            <el-select v-model="filter.AccountNo" placeholder="Select" clearable filterable>
              <el-option v-for="item in customerList" :key="item.value" :label="item.CompanyName" :value="item.AccountNo"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="table-container">
      <div class="view-info">
        <p>You are viewing page {{ filter.Page }} out of a total of {{ totalCount.PageCount }} pages. (Total Records: {{ totalCount.TotalCount }})</p>
        <el-button class="refresh-btn" @click="arrowsRotate"> <i class="icon-arrows-rotate-solid"></i></el-button>
      </div>
      <el-table v-if="productsList.length > 0" :data="productsList" style="width: 100%; padding: 0" v-on:sort-change="sortHandler">
        <el-table-column fixed prop="SKU" label="SKU" align="center" min-width="120" sortable="custom"> </el-table-column>
        <el-table-column width="120" label="Annual Consumption" align="center">
          <template slot-scope="scope">
            <trend :data="scope.row.Statistics.ChartData.datasets[0].data" :gradient="['black', 'black', 'black']" :padding="1" :radius="1" :stroke-width="1" auto-draw smooth> </trend>
          </template>
        </el-table-column>
        <el-table-column prop="Customer.CompanyName" label="Customer" align="center" min-width="150"> </el-table-column>

        <el-table-column prop="Title" label="Product Name" align="center" min-width="150"></el-table-column>
        <el-table-column label="Warehouses" align="center" min-width="150">
          <el-table-column :label="item.WarehouseTitle" align="center" min-width="120" v-for="(item, index) in productsList[0].Statistics.WarehouseBoxList" :key="index + 'Warehouse'">
            <template slot-scope="scope">
              {{ scope.row.Statistics.WarehouseBoxList.find((x) => x.WarehouseId == item.WarehouseId).BoxQuantity }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="Statistics.TotalAvailable" label="Total Available" align="center" min-width="140" sortable="custom"></el-table-column>
        <el-table-column label="Available Duration" align="center">
          <el-table-column prop="Statistics.AvailableDuration3Months" label="3 M" align="center" min-width="90"></el-table-column>
          <el-table-column prop="Statistics.AvailableDuration12Months" label="12 M" align="center" min-width="90"></el-table-column>
        </el-table-column>
        <el-table-column prop="Statistics.InProduction" label="Production" align="center" min-width="120" sortable="custom"></el-table-column>
        <el-table-column prop="Statistics.InSupplierDepot" label="Supplier Depot" align="center" min-width="150" sortable="custom"></el-table-column>
        <el-table-column prop="Statistics.InTransit" label="Transit" align="center" min-width="90" sortable="custom"></el-table-column>
        <el-table-column prop="Statistics.Total" label="Total" align="center" min-width="90" sortable="custom"></el-table-column>
        <el-table-column label="Total Available Duration" align="center">
          <el-table-column prop="Statistics.TotalAvailableDuration3Months" label=" 3 M" align="center" min-width="90"></el-table-column>
          <el-table-column prop="Statistics.TotalAvailableDuration12Months" label=" 12 M" align="center" min-width="90"></el-table-column>
        </el-table-column>
        <el-table-column prop="Statistics.Consumption3Months" label="3 Months Consumption" align="center" min-width="120" sortable="custom"></el-table-column>
        <el-table-column prop="Statistics.Consumption12Months" label="12 Months Consumption" align="center" min-width="120" sortable="custom"></el-table-column>

        <el-table-column fixed="right" label="Actions" align="center" min-width="150">
          <template slot-scope="scope">
            <el-button type="primary" class="add-btn" @click="seeDetailsOpenDialog(scope.row.ID)">See Details<i class="icon-pen-to-square-solid"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <div class="info">
          <span>Show</span>
          <el-select v-model="filter.PageSize">
            <el-option label="12" :value="12"></el-option>
            <el-option label="24" :value="24"></el-option>
            <el-option label="36" :value="36"></el-option>
            <el-option label="100" :value="100"></el-option>
            <el-option label="200" :value="200"></el-option>
          </el-select>
          <span>entries</span>
        </div>
        <el-pagination layout="prev, pager, next" :current-page.sync="filter.Page" :page-size="filter.PageSize" :total="totalCount.TotalCount"> </el-pagination>
      </div>
    </div>
    <el-dialog custom-class="create-new-offer" top="5vh" :close-on-click-modal="false" :visible.sync="addNewProductDialog" :destroy-on-close="true" v-if="addNewProductDialog" :show-close="false">
      <addProduct @close="closeDialog()" v-if="addNewProductDialog" />
    </el-dialog>

    <el-dialog custom-class="see-details" top="5vh" :close-on-click-modal="false" :visible.sync="seeDetails" :destroy-on-close="true" :show-close="false">
      <productSeeDetails @close="seeDetailsCloseDialog()" v-if="seeDetails" :id="setProductId" />
    </el-dialog>
  </div>
</template>

<script>
import productSeeDetails from "../../components/productSeeDetails.vue";
import addProduct from "../../components/addProduct.vue";
export default {
  name: "Products",
  components: {
    productSeeDetails,
    addProduct,
  },
  data() {
    return {
      seeDetails: false,
      setProductId: null,
      addNewProductDialog: false,
      filterActive: false,
      dateRange: null,
      loading: false,
      timeout: null,
    };
  },
  async created() {
    await Promise.all([this.$store.dispatch("getProductsList", this.filter), this.$store.dispatch("getCustomersList", { Page: 1, PageSize: 999999, SearchTerm: null }), this.$store.dispatch("getWarehouseList")]);
  },

  mounted() {
    this.jQuery(".filter-container").hide();
  },

  methods: {
    async sortHandler(params) {
      // params objesi: {columns, prop, order}
      console.log(params);
      this.filter.SortBy = params.prop;
      this.filter.SortDirection = params.order == "descending" ? 1 : 0;
    },
    openAddDialog(item) {
      if (item != null) {
        this.loading = "openAddDialogLoading" + item;
      } else {
        this.loading = "openAddDialogLoading";
      }
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.setId = item;
        this.$store.commit("setWarehouseValidation", []);
        this.addNewProductDialog = true;
        this.loading = false;
      }, 500);
    },

    closeDialog() {
      this.addNewProductDialog = false;
    },

    seeDetailsCloseDialog() {
      this.seeDetails = false;
    },
    seeDetailsOpenDialog(id) {
      this.setProductId = id;
      this.seeDetails = true;
    },
    arrowsRotate() {
      this.$store.dispatch("getProductsList", { isDesignConfirmed: true });
    },
    toggle() {
      this.filterActive = !this.filterActive;
      this.jQuery(".filter-container").slideToggle();
    },
    clearFilter() {
      this.filter.SearchTerm = null;
      this.filter.CustomerId = null;
      this.filter.Title = null;
      this.filter.SKU = null;
    },

    exportFile() {
      var label = "Product.xlsx";
      this.$client
        .get("/Product/ExportReports", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
  computed: {
    filter() {
      return this.$store.getters.getProductsFilter;
    },
    totalCount() {
      return this.$store.getters.getProductsTotal;
    },
    productsList() {
      return this.$store.getters.getProductsList;
    },
    customerList() {
      return this.$store.getters.getCustomersList;
    },
    getWarehouseList() {
      return this.$store.getters.getWarehouseList;
    },
  },

  watch: {
    filter: {
      deep: true,
      handler: async function () {
        await this.$store.dispatch("getProductsList", this.filter);
      },
    },
  },
};
</script>

<style></style>
